import { UnauthenticatedWrapperNoFooter } from '@layouts/Wrapper'
import { useTheme } from '@libs/useTheme'
import Box from '@components/base/Box'
import Header from '@components/landing/Header'
import HarvestSection from '@components/landing/HarvestSection'
import FooterUnauthenticated from '@components/page/FooterUnauthenticated'
import InfoSection from '@components/landing/InfoSection'
import Testimonials from '@components/landing/Testimonials'
import JoinForm from '@components/landing/JoinForm'
import webinarPromo from '@public/webinar-promo.jpg'
import webinarPromoMobile from '@public/webinar-promo-mobile.png'
import bgHome from '@public/images/home_bg_no_blobs.png'

import Image from 'next/image'
import { useEffect, useState } from 'react'

export default function Home(): JSX.Element {
  const { theme } = useTheme()
  const [backgroundWidthFactor, setBackgroundWidthFactor] = useState<number>(1)

  const handleResize = () => {
    let factor, width
    width = window.innerWidth / 250
    factor = width

    // Reset scalling when on large screen
    if (width > 3)
      return backgroundWidthFactor !== 1
        ? setBackgroundWidthFactor(1)
        : undefined
    if (width < 1.3 || width > 2) return setBackgroundWidthFactor(2)

    setBackgroundWidthFactor(factor ?? backgroundWidthFactor)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  return (
    <Box
      color={'backgroundColour'}
      style={{
        backgroundImage: `url(${bgHome.src})`,
        backgroundSize: `${Math.floor(100 * backgroundWidthFactor)}% 100%`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: theme.backgroundColour,
      }}
    >
      <Header />
      <Box
        className="w-full h-auto cursor-pointer"
        style={{ animationDelay: '1s' }}
        onClick={() =>
          window.open(
            'https://www.forumm.to/event/forumm-academy-webinar',
            '_blank'
          )
        }
      >
        <Box className="flex w-full justify-center items-center mt-4 sm:mt-10 mb-10 sm:px-10">
          <Box className="flex max-w-[1500px] sm:rounded-3xl overflow-hidden">
            <Image
              src={webinarPromo}
              alt="Webinar Promo"
              className="w-full h-auto hidden md:block"
            />
            <Image
              src={webinarPromoMobile}
              alt="Webinar Promo"
              className="w-full h-auto block md:hidden"
            />
          </Box>
        </Box>
      </Box>
      <InfoSection />
      <Testimonials />
      <HarvestSection />
      <JoinForm />
      <FooterUnauthenticated transparent={true} />
    </Box>
  )
}

Home.Layout = UnauthenticatedWrapperNoFooter
